<template>
  <div class="app-wrapper">
    <BaseNavigation />
    <DividerNavigation />
    <div class="p-2 mx-auto">
      <div class="d-flex flex-row">
        <div style="width: 100%" class="pl-25">
          <b-button
            :variant="`${!toogleFormSendContact ? 'outline-' : ''}danger`"
            block
            @click="toogleFormSendContact = !toogleFormSendContact"
          >
            {{ toogleFormSendContact ? "Batalkan " : "Send Contact" }}
          </b-button>
        </div>
        <!-- <div style="width: 50%" class="pl-25">
          <b-button
            variant="outline-danger"
            block
            @click="$router.push('/krip/jadwal')"
          >
            Lihat Jadwal
          </b-button>
        </div> -->
      </div>

      <b-form v-if="toogleFormSendContact" class="mt-1" @submit.prevent>
        <validation-observer ref="formCreateContacts">
          <b-form-group label="Subject" label-for="contact_subject">
            <validation-provider
              #default="{ errors }"
              name="Subject"
              rules="required"
            >
              <b-form-input
                id="contact_subject"
                v-model="contact_forms.contact_subject"
                :state="errors.length > 0 ? false : null"
                name="contact_subject"
                type="text"
                placeholder="isi subject..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Email" label-for="contact_email">
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required"
            >
              <b-form-input
                id="contact_email"
                v-model="contact_forms.contact_email"
                :state="errors.length > 0 ? false : null"
                name="contact_email"
                type="text"
                placeholder="isi email..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Message" label-for="contact_message">
            <validation-provider
              #default="{ errors }"
              name="Message"
              rules="required"
            >
              <quill-editor
                v-model="contact_forms.contact_content"
                :options="snowOption"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            type="submit"
            variant="outline-danger"
            block
            @click="validationContacts"
          >
            Send Contact
          </b-button>
        </validation-observer>
        <hr />
      </b-form>
      <div class="mt-1">
        <b-card
          v-for="item in contacts.data"
          :key="item.id"
          class="shadow-none border p-1 mb-1"
          no-body
        >
          <div class="d-flex pb-1 border-bottom">
            <div>
              <div class="font-weight-bold">#CONTACTS-{{ item.id }}</div>
              <div class="p-1">
                <table>
                  <tbody>
                    <tr>
                      <td>Email</td>
                      <td class="font-weight-bold">
                        : {{ item.contact_email }}
                      </td>
                    </tr>
                    <tr>
                      <td>Subject</td>
                      <td class="font-weight-bold">
                        : {{ item.contact_subject }}
                      </td>
                    </tr>
                    <tr>
                      <td>Pesan:</td>
                      <td class="font-weight-bold">
                        <br />
                        <p v-html="item.contact_content"></p>
                      </td>
                    </tr>
                    <tr>
                      <td>Dibuat</td>
                      <td class="font-weight-bold">: {{ item.created_at }}</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <b-badge variant="light-success font-weight–light mt-25">
                        {{ "sudah terkirim" }}
                      </b-badge>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- <div
              class="ml-auto pointer text-danger"
              @click="hapusBerkasSKP(item.id)"
            >
              <feather-icon icon="TrashIcon" size="16" class="align-middle" />
            </div> -->
          </div>
          <!-- <div class="pb-1 pt-1">
            <b-img
              v-if="!isPDF(item)"
              :src="getSKPFile(item)"
              fluid
              class="mb-25"
            />
            <div
              v-else
              class="text-primary text-underline pointer"
              @click="viewPdf(getSKPFile(item))"
            >
              <u>Lihat Berkas SKP</u>
            </div>
          </div> -->
        </b-card>

        <div
          v-if="contacts.isLoading"
          class="d-flex justify-content-center mb-1"
        >
          <b-spinner label="Loading..." variant="danger" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */

import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import {
  BButton,
  BCard,
  BImg,
  BSpinner,
  BBadge,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
  // BBadge,
} from "bootstrap-vue";
import BaseNavigation from "@/components/Base/BaseNavigation.vue";
import DividerNavigation from "@/components/Base/DividerNavigation.vue";
// import { required, email } from '@validations'
import apis from "@/api";
import storage from "@/helpers/storage";
import { required } from "@validations";
import { quillEditor } from "vue-quill-editor";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BaseNavigation,
    DividerNavigation,
    BButton,
    BCard,
    BImg,
    BBadge,
    BSpinner,
    BForm,
    BFormInput,
    quillEditor,
    BFormSelect,
    BFormGroup,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    // BBadge,
  },
  data() {
    return {
      required,
      examplePicStudiKasus: require("@/assets/images/pages/Studi_Kasus_Contoh.jpeg"),
      contacts: {
        data: null,
        isLoading: false,
        nextPageUrl: null,
      },
      tempFileBerkas: null,
      fileContacts: null,
      toogleFormSendContact: false,
      contact_forms: {
        contact_email: "",
        contact_subject: "",
        contact_content: "",
      },
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
        theme: "snow",
        placeholder: "silahkan ketikan pesan contact ...",
      },
    };
  },
  computed: {
    // https://www.idijakpus.or.id/uploads/krip/krip_file/1473/A2.png
    getSKPFile() {
      // DEPRECATED code below
      // return (item) => {
      //   if (item.skp_file) {
      //     if (!item.skp_file.includes("https")) {
      //       return `https://www.idijakpus.or.id/uploads/krip/skp_file/${item.id}/${item.skp_file}`;
      //     }
      //     return item.skp_file;
      //   }
      //   return null;
      // };
      //new code
      // return (item) => {
      //   if (item.skp_file) {
      //     if (item.skp_file.includes("https")) {
      //       return item.skp_file;
      //     }
      //   }
      //   return null;
      // };
    },
    // isPDF() {
    //   return (item) => {
    //     if (item.skp_file?.includes(".pdf")) {
    //       return true;
    //     }
    //     return false;
    //   };
    // },
  },
  mounted() {
    document
      .getElementsByClassName("app-wrapper")[0]
      .addEventListener("scroll", this.scrollCallback);
  },
  created() {
    this.fetchContacts();
  },
  methods: {
    viewPdf(url) {
      storage.setStorage("pdfUrl", url);
      this.$router.push({ path: "/pdfview" });
    },
    scrollCallback() {
      const element = document.getElementsByClassName("app-wrapper")[0];
      const { scrollTop } = element;
      const { scrollHeight } = element;
      const { clientHeight } = element;
      if (scrollTop + clientHeight + 100 >= scrollHeight) {
        if (!this.contacts.isLoading && this.contacts.nextPageUrl) {
          this.fetchContacts(this.contacts.nextPageUrl);
        }
      }
    },
    // onChangeContacts(e) {
    //   const { files } = e.target;
    //   if (files.length) {
    //     this.createFileContacts(files[0], (result) => {
    //       this.contact_forms.skp_file = result;
    //     });
    //   }
    // },
    // createFileContacts(file, cb) {
    //   const reader = new FileReader();

    //   reader.onload = (e) => {
    //     cb(e.target.result);
    //   };
    //   reader.readAsDataURL(file);
    // },
    validationContacts() {
      this.$refs.formCreateContacts.validate().then((success) => {
        if (success) {
          this.simpanContacts();
        }
      });
    },
    fetchContacts(url) {
      this.contacts.isLoading = true;
      apis.profile
        .getContact(url)
        .then(({ data }) => {
          if (url) {
            this.contacts.data = this.contacts.data.concat(data.data);
          } else {
            this.contacts.data = data.data;
          }
          this.contacts.nextPageUrl = data.next_page_url;
        })
        .finally(() => {
          this.contacts.isLoading = false;
        });
    },
    simpanContacts() {
      this.$store.commit("app/UPDATE_LOADING_BLOCK", true);
      apis.profile
        .createContact({
          contact_email: this.contact_forms.contact_email,
          contact_subject: this.contact_forms.contact_subject,
          contact_content: this.contact_forms.contact_content,
        })
        .then(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Berhasil submit contacts",
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.toogleFormSendContact = false;
          this.fetchContacts();
        })
        .catch((error) => {
          this.errorHandler(
            error,
            "Gagal submit contact, silahkan coba lagi nanti"
          );
        })
        .finally(() => {
          this.$store.commit("app/UPDATE_LOADING_BLOCK", false);
        });
    },
    // hapusContacts(id) {
    //   this.$swal({
    //     title: "Apakah kamu yakin?",
    //     text: "Berkas yang sudah dihapus, tidak bisa dikembalikan",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonText: "Ya, hapus!",
    //     cancelButtonText: "Batal",
    //     customClass: {
    //       confirmButton: "btn btn-primary",
    //       cancelButton: "btn btn-outline-danger ml-1",
    //     },
    //     buttonsStyling: false,
    //   })
    //     .then((result) => {
    //       if (result.value) {
    //         this.$store.commit("app/UPDATE_LOADING_BLOCK", true);
    //         return apis.profile.deleteSkp(id);
    //       }
    //       return false;
    //     })
    //     .then((result) => {
    //       if (result) {
    //         this.$store.commit("app/UPDATE_LOADING_BLOCK", false);
    //         this.fetchContacts();
    //         this.$toast({
    //           component: ToastificationContentVue,
    //           props: {
    //             title: "Berhasil menghapus berkas",
    //             icon: "CheckIcon",
    //             variant: "success",
    //           },
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       this.errorHandler(
    //         error,
    //         "Gagal hapus berkas, silahkan coba lagi nanti"
    //       );
    //     });
    // },
  },
};
</script>
